import React, { useState, useEffect } from 'react';
import PhotosGrid from '../../../components/PhotosGrid/PhotosGrid';
import { useFormatMessage } from 'react-intl-hooks';
import {Helmet} from "react-helmet";

const FunPhotos = () => {
	const intl = useFormatMessage();

	const [content, setContent] = useState([]);

	useEffect(() => {
		fetch(process.env.REACT_APP_API_END_POINT+'content/fun-photo.php')
			.then((response) => response.json())
			.then((data) => {
				setContent(data);
			});
	}, []);

	return (
		<div>
			<Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/gallery/fun-photos"} />
			</Helmet>
			<PhotosGrid content={content} title={intl({id: 'funPhoto.title'})} />
		</div>
	);
};

export default FunPhotos;
