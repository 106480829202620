import React from 'react';

import './Typography.scss';

const Heading3 = ({ text, color = '#cea697', textAlign }) => {

	return (
		<h3 className='typography-heading3' style={{ color: color, textAlign: textAlign }}>
			{text}
		</h3>
	);
};

export default Heading3;
