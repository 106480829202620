import React from 'react';
import 逗猫棒 from 'assets/逗猫棒.svg';
import 猫咪 from 'assets/猫咪.svg';

import './CatAnimation.scss';

const CatAnimation = () => (
    <div className='cat-animation-wrapper'>
        <div className='cat-animation'>
            <img className='toy' src={逗猫棒} alt='cartoon cat' />
            <img className='cat' src={猫咪} alt='cartoon cat' />
        </div>
    </div>
)

export default CatAnimation;