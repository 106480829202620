import React from 'react';
import { Link } from 'react-router-dom';
import Heading3 from 'components/Typography/Heading3';
import { useFormatMessage } from 'react-intl-hooks';
// import FOOTER_DATA from './FooterData';

import './FooterMenu.scss';


const FooterContent = () => {
	const intl = useFormatMessage();

	const aboutUsVersion =
    localStorage.getItem("lang") === "en" || !localStorage.getItem("lang")
      ? "/about-en"
      : "/about-zh";

	const FOOTER_DATA = [
		{
			title: 'Cattery',
			key: 'cattery',
			content: [
				{
					name: intl({id: 'header.about'}),
					key: intl({id: 'header.about'}),
					link: aboutUsVersion,
				},
				{
					name: intl({id: 'header.availableKittens'}),
					key: intl({id: 'header.availableKittens'}),
					link: 'available-kittens',
				},
				{
					name: intl({id: 'header.deposit'}),
					key: intl({id: 'header.deposit'}),
					link: '/deposit',
				},
			],
		},
		{
			title: 'Support',
			key: 'support',
			content: [
				{
					name: intl({id: 'header.faqs'}),
					key: intl({id: 'header.faqs'}),
					link: '/faq',
				},
				{
					name: intl({id: 'footer.nav.email'}),
					key: 'email',
					link: 'mailto:'+intl({id: 'global.email'}),
				},
				{
					name: intl({id: 'global.phone'}),
					key: 'tel',
					link: 'Text:'+intl({id: 'global.phone'}),
				},
			],
		},
	];

	return (
		<div className='footer-menu'>
			{FOOTER_DATA.map((link) => (
				<div key={link.key} className='contents-category'>
					<Heading3 text={link.title} />
					<div className='content'>
						{link.content.map((v) =>
							v.key === 'email' || v.key === 'tel' ? (
								v.name === "000-000-0000" ? (
									" "
								) : 
									<a key={v.key} href={v.link}>{v.name}</a>
							) : (
								<Link key={v.key} to={v.link}>
									{v.name}
								</Link>
							),
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default FooterContent;
