import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';

import Header from 'components/Typography/Header';
import Heading4 from '../../components/Typography/Heading4';

import './NotFound.scss';

const NotFound = () => {
	const intl = useFormatMessage();
	return (
		<div className="notFound-page">
			<Header title={intl({id: 'notFound.title'})} />
            <Heading4 text={intl({ id: 'notFound.content'})} />
            <img
                src={intl({id: 'notFound.img'})}
                alt="404 page image"
            />
		</div>
	);
};

export default NotFound;
