import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, Button } from 'antd';
import { Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useFormatMessage } from "react-intl-hooks";

import NavbarLogo from '../../../assets/navbar-logo.svg';
import LangSwitch from '../../LangSwitch/LangSwitch';

import './MobileNav.scss';

const { Item, SubMenu } = Menu;

const MobileNav = ({ isWhite }) => {
	const intl = useFormatMessage();

	const [visible, setVisible] = useState(false);
	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const aboutUsVersion =
    localStorage.getItem("lang") === "en" || !localStorage.getItem("lang")
      ? "/about-en"
      : "/about-zh";

	return (
		<div
			className='mobile-nav'
			style={{ 
				backgroundColor: isWhite ? 'white' : 'transparent', 
				boxShadow: isWhite ? '0px 2px 12px #dfdfdf' : undefined 
			}}
		>
			<Link to='/'>
				<img alt='logo' className='navbar-logo' src={NavbarLogo} />
			</Link>
			<Button type='default' onClick={showDrawer}>
				<MenuOutlined />
			</Button>
			<Drawer
				placement='right'
				closable={false}
				onClose={onClose}
				visible={visible}
			>
				<Menu mode='inline'>
					<Item>
						<Link to={aboutUsVersion}>{intl({ id: "header.about", defaultMessage: "About" })}</Link>
					</Item>
					<Item>
						<Link to='/kings'>{intl({ id: "header.kings", defaultMessage: "Kings" })}</Link>
					</Item>
					<Item>
						<Link to='/queens'>{intl({ id: "header.queens", defaultMessage: "Queens" })}</Link>
					</Item>
					<SubMenu key='kittens' title={intl({id: "header.kittensTitle",defaultMessage: "Kittens"})}>
						<Item key='available-kitten'>
							<Link to='/available-kittens'>{intl({id: "header.availableKittens",defaultMessage: "Available Kittens"})}</Link>
						</Item>
						<Item key='prev-kitten'>
							<Link to='/previous-kittens'>{intl({id: "header.previousKittens",defaultMessage: "Previous Kittens"})}</Link>
						</Item>
					</SubMenu>
					<SubMenu key='shows' title={intl({id: "header.shows",defaultMessage: "Our Shows"})}>
						<Item key='show-results'>
							<Link to='/gallery/show-results'>{intl({id: "header.showResults",defaultMessage: "Show Results"})}</Link>
						</Item>
						<Item key='/show-photos'>
							<Link to='/gallery/show-photos'>{intl({id: "header.showPhotos",defaultMessage: "Show Photos"})}</Link>
						</Item>
					</SubMenu>
					<SubMenu key='gallery' title={intl({id: "header.gallery",defaultMessage: "Gallery"})}>
						<Item key='/fun-photos'>
							<Link to='/gallery/fun-photos'>{intl({id: "header.funPhotos",defaultMessage: "Show Results"})}</Link>
						</Item>
					</SubMenu>
					<Item>
						<Link to='/deposit'>{intl({id: "header.deposit",defaultMessage: "Deposit"})}</Link>
					</Item>
					<Item>
						<Link to='/faq'>{intl({id: "header.faqs",defaultMessage: "FAQS"})}</Link>
					</Item>
					<Item>
						<Link to='/contactus'>{intl({id: "header.contactUs",defaultMessage: "Contact Us"})}</Link>
					</Item>
					<Item>
						<Link to={{ pathname: "https://mipetus.com/" }} target="_blank">
							{intl({id: "header.mipet",defaultMessage: "MIPET"})}
						</Link>
					</Item>
				</Menu>
				<LangSwitch />
			</Drawer>
		</div>
	);
};

export default MobileNav;
