import React from 'react';
import Heading3 from '../Typography/Heading3';
import Placeholder from '../Typography/Placeholder';
import Paragraph from '../Typography/Paragraph';
import { useFormatMessage } from 'react-intl-hooks';

import './Popup.scss';

const Popup = ({ setShowPopup, data }) => {

	const intl = useFormatMessage();

	return (
		<div className='popup-wrapper'>
			<div className='popup-container'>
				<div
					onClick={() =>
						setShowPopup((prevState) => ({
							...prevState,
							[data.name]: false,
						}))
					}
				>
					<Paragraph text='X' />
				</div>
				<Heading3 text={data.name} />
				{data.barcode ? (
					<div>
						<div className='barcode'>
							<img src={data.barcode} alt='barcode' />
						</div>
						<Placeholder text={data.msg} />
						{data.name === 'Paypal' && <div><b>{intl({id: 'deposit.pop.PaypalText2'})}</b></div>}
					</div>
				) : (
					<a href={data.text}>{data.msg}</a>
				)}
			</div>
		</div>
	);
};

export default Popup;
