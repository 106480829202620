import React, {  useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormatMessage } from "react-intl-hooks";
import {Helmet} from "react-helmet";

import KittenPreview from "components/KittenPreview/KittenPreview";
import Header from "components/Typography/Header";
import Paragraph from "components/Typography/Paragraph";
import Button from "components/Button/Button";

import "./AvailableKittens.scss";

const AvailableKittens = () => {
    const [finishloading,setfinishloading] = useState(false);
    const intl = useFormatMessage();

    const [availableKitten,setavailableKitten] = useState([]);
    useEffect(()=>{
		fetch(process.env.REACT_APP_API_END_POINT+'content/available-kittens.php')
			.then((response) => response.json())
			.then((data) => {
				setavailableKitten(data);
                setfinishloading(true);
			});
	}, [])


    return (finishloading ?
        <div className="available-kittens-page">
            <Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/available-kittens"} />
			</Helmet>
            <Header
                title={intl({
                    id: "availableKittens.title",
                    defaultMessage: "Available Kittens",
                })}
            />
            {/* kitten */}
            

            {availableKitten.length > 0 ? (
                // kitty available page
                <div>
                    <div className="no-available-kitten">
                    <Paragraph
                        text={intl({
                            id: "availableKittens.waitList",
                            defaultMessage: "All our kittens are booked.",
                        })}
                    />
                    <br />
                    </div>
                    <div className="kittens-list">
                    
                    {availableKitten?.map((kitten) => (
                        <KittenPreview data={kitten} key={kitten.id} />
                    ))}
                </div>
                </div>
            ) : (
                <div className="no-available-kitten">
                    <Paragraph
                        text={intl({
                            id: "availableKittens.content.noAvailableKittens",
                            defaultMessage: "All our kittens are booked.",
                        })}
                    />
                    <br />
                    <Link to="/deposit">
                        <Button>
                            {intl({
                                id: "availableKittens.button.joinWaitList",
                                defaultMessage: "Join Waitlist",
                            })}
                        </Button>
                    </Link>
                    <div style={{ textAlign: "center", padding: "2rem" }}>
                        <iframe
                            style={{ width: "90%", height: "500px" }}
                            src={intl({id: "availableKittens.video"})}
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="video"
                        />
                    </div>
                </div>
                
                
            )}
            
            
        </div> : <Header title={"Loading"}/>
    );
};

export default AvailableKittens;
