import React from 'react';
import { withRouter } from 'react-router-dom';

import './KittenPreview.scss';

const KittenPreview = (props) => {
	const { data, history } = props;
	const { birthday, color, path, gender, description, imageUrl, id } = data;
	const currentPathName = history.location.pathname;

	const previewDescription = () => {
		if (description) {
			const cutDescription = description.split(' ').slice(0, 25);
			const previewText = cutDescription.join(' ');
			return previewText;
		}
		return '';
	};

	const calcMonth = () => {
		if(birthday){
			const current = new Date();
			const bDay = birthday.split('.');
			const month = +bDay[0];
			const day = +bDay[1];
			const year = +bDay[2];
			let rVal = (current.getFullYear()-year)*12 + current.getMonth()+1 - month;
			if(current.getDate() < day) rVal--;
			return rVal;
		}
		return '';
	}

	if (!data) {
		return null;
	}

	return (
		<div key={id} className='kitten-preview-wrapper'>
			<div className='image-wrapper'>
				{ imageUrl && <img alt='kitty preview' src={imageUrl} /> }
			</div>
			<div className='info-wrapper'>
				<h3>{ data[`name${localStorage.lang}`].toUpperCase()}</h3>
				<span> • Birthday: {birthday}</span><br></br>
				<span> • Month: {calcMonth(birthday)}</span><br></br>
				<span> • {gender.charAt(0).toUpperCase() + gender.slice(1)}</span><br></br>
				<span> • {color}</span>
				<p className='description'>{previewDescription()}</p>
				<button
					className='preview-link'
					onClick={() => history.push(`${currentPathName}/${path}`)}
				>
					More photos
				</button>
			</div>
		</div>
	);
};

export default withRouter(KittenPreview);
