import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { message, Spin, Select } from 'antd';
import { Form, Input } from 'antd';
import { sendMessage } from 'utils/emailJS';
import Button from 'components/Button/Button';

import './ContactForm.scss';

const { Option } = Select;

const ContactForm = () => {
	const [form] = Form.useForm();
	const intl = useFormatMessage();

	const [ submitting, setSubmitting ] = useState(false);
	const [emailData, setEmailData] = useState({
		firstName: '',
		lastName: '',
		residential: '',
		employment: '',
		currentPetsNum: '',
		email: '',
		phone: '',
		message: '',
		hearAboutUs: '',
	});

	const handleInputChange = (event) => {
		event.preventDefault();
		const { target } = event;
		const name = target.name;
		const value = target.value;
		setEmailData((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleSelect = (name, value) => {
		setEmailData((prevState) => ({ ...prevState, [name]: value }));
	}

	const validateMessages = {
		required: 'Name is required!',
		types: {
			email: 'Please enter validate email',
		},
	};

	const handleSubmit = async () => {
		setSubmitting(true);
		try {
			const response = await sendMessage(emailData);
			console.log('SUCCESS!', response.status, response.text);
			message.success(intl({id: 'contactUs.form.success'}));
		} catch(err) {
			console.error('FAILED...', err);
            message.error(intl({id: 'contactUs.form.error'}))
		} finally {
			setSubmitting(false);
		}
		form.resetFields();
	};

	return (
		<Spin spinning={submitting}>
			<Form
				className='contact-form'
				id='contact-form'
				form={form}
				name='contact-form'
				layout='vertical'
				validateMessages={validateMessages}
				onFinish={handleSubmit}
			>
				<Input.Group compact>
					<Form.Item
						name='firstName'
						label={intl({id: 'contactUs.form.firstName'})}
						rules={[{ required: true }]}
						style={{ width: '45%', marginRight: '10%' }}
					>
						<Input
							name='firstName'
							onChange={handleInputChange}
							value={emailData.firstName}
						/>
					</Form.Item>

					<Form.Item
						name='lastName'
						label={intl({id: 'contactUs.form.lastName'})}
						rules={[{ required: true }]}
						style={{ width: '45%' }}
					>
						<Input
							name='lastName'
							onChange={handleInputChange}
							value={emailData.lastName}
						/>
					</Form.Item>
				</Input.Group>

				<Input.Group compact>
					<Form.Item
						name='phone'
						label={intl({id: 'contactUs.form.phone'})}
						rules={[{ required: true }]}
						style={{ width: '45%', marginRight: '10%' }}
					>
						<Input
							name='phone'
							onChange={handleInputChange}
							value={emailData.phone}
						/>
					</Form.Item>

					<Form.Item
						name='email'
						label={intl({id: 'contactUs.form.email'})}
						rules={[{ type: 'email', required: true }]}
						style={{ width: '45%' }}
					>
						<Input
							name='email'
							onChange={handleInputChange}
							value={emailData.email}
						/>
					</Form.Item>
				</Input.Group>

				<Input.Group compact>
					<Form.Item
						label={intl({id: 'contactUs.form.residential'})}
						name="residential"
						rules={[{required: true}]}
						style={{ width: '45%', marginRight: '10%' }}
					>
						<Select placeholder={intl({id: 'contactUs.form.pleaseholder'})} onSelect={(v) => handleSelect('residential', v)} value={emailData.residential}>
							{intl({id: 'contactUs.form.residentialOption'}).split('|').map((ele, index) => (
								<Option value={ele} key={index}>{ele}</Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						label={intl({id: 'contactUs.form.position'})}
						name="employment"
						rules={[{required: true}]}
						style={{ width: '45%' }}
					>
						<Select placeholder={intl({id: 'contactUs.form.pleaseholder'})} onSelect={(v) => handleSelect('employment', v)} value={emailData.employment}>
							{intl({id: 'contactUs.form.positionOption'}).split('|').map((ele, index) => (
								<Option value={ele} key={index}>{ele}</Option>
							))}
						</Select>
					</Form.Item>
				</Input.Group>

				<Input.Group compact>
					<Form.Item
						label={intl({id: 'contactUs.form.pets'})}
						name="currentPetsNum"
						rules={[{required: true}]}
						style={{ width: '45%', marginRight: '10%' }}
					>
						<Input
							name='currentPetsNum'
							onChange={handleInputChange}
							value={emailData.currentPetsNum}
						/>
					</Form.Item>

					<Form.Item
						label={intl({id: 'contactUs.form.hearAboutUs'})}
						name="hearAboutUs"
						rules={[{required: true}]}
						style={{ width: '45%' }}
					>
						<Select placeholder={intl({id: 'contactUs.form.pleaseholder'})} onSelect={(v) => handleSelect('hearAboutUs', v)} value={emailData.employment}>
							{intl({id: 'contactUs.form.hearAboutUsOption'}).split('|').map((ele, index) => (
								<Option value={ele} key={index}>{ele}</Option>
							))}
						</Select>
					</Form.Item>
				</Input.Group>

				<Form.Item name='message' label={intl({id: 'contactUs.form.tellMore'})} rules={[{ required: true }]}>
					<Input.TextArea
						className='form-text-area'
						name='message'
						onChange={handleInputChange}
						style={{ height: '12rem' }}
					/>
				</Form.Item>
				<div className='button-wrapper'>
					<Button htmlType='submit'>{intl({id: 'contactUs.form.button'})}</Button>
				</div>
			</Form>
		</Spin>
	);
};

export default ContactForm;
