import React from 'react';
import './Typography.scss';

export default function Placeholder(props) {
	const { text, color } = props;

	return (
		<span className='typography-placeholder' style={{ color }}>
			{text}
		</span>
	);
}
