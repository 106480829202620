import React from 'react';

import './Typography.scss';

const Header = (props) => {
	const { title, color } = props;

	return (
		<div className='typography-header'>
			<h1 style={{ color }}>{title}</h1>
		</div>
	);
};

export default Header;
