import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Heading3 from "components/Typography/Heading3";
import Heading4 from "components/Typography/Heading4";

import "./ShowResultItem.scss";

const ShowResultItem = ({ data, history, match }) => {
  const [currentPath, setCurrentPath] = useState("");

  const currentPathName = history.location.pathname;

  useEffect(() => {
    setCurrentPath(currentPathName);
  }, [currentPath, currentPathName]);

  return (
    <div className="show-result-item-wrapper">
      <img
        className="cat-img"
        src={data.imageUrl}
        alt="cat"
        width="auto"
        height="400px"
        style={{ objectFit: "cover" }}
      />
      <Heading3 color="#BC8A77" text={data.fullName} />
      {data[`awards${localStorage.lang}`] &&
        data[`awards${localStorage.lang}`].map((v, i) => (
            <Heading4 color="#BFA48F" text={v} />
        ))}
    </div>
  );
};

export default withRouter(ShowResultItem);
