import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import { useFormatMessage } from 'react-intl-hooks';

import Button from "components/Button/Button";
import Header from "components/Typography/Header";
import Heading3 from "components/Typography/Heading3";

import "./AboutUs.scss";

export const AboutUsEng = () => {
  const intl = useFormatMessage();

  const [finishloading,setfinishloading] = useState(false);
  const [content,setContent] = useState({});

	useEffect(()=>{
		fetch(process.env.REACT_APP_API_END_POINT+'content/aboutEn.php')
			.then((response) => response.json())
			.then((data) => {
				setContent(data);
        setfinishloading(true);
			});
	}, [])

  
  return (finishloading ?
    <div className="about-us-page eng-version">
      <Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/about-en"} />
			</Helmet>
      <div className="inner-wrapper">
        <Header title={intl({ id: 'about.title' })} />
        <Heading3 text={content.s1_title} />

        {/* Intro */}
        <div className="content">
          <img src={content.s1_img_1} alt="Los Angeles Ragdoll Fujipaws ragdoll owner photos" />
          {content.s1_p_1}
        </div>

        <div className="content">
          {content.s1_p_2}
          <div className='image-containers'>
            <img src={content.s1_img_2} alt="California Ragdoll kitten" />
            <img src={content.s1_img_3} alt="Available Ragdoll Kittens" style={{ objectFit: 'contain' }} />
          </div>
        </div>

        <div className="content">
          {content.s1_p_3}
          <div className='image-containers'>
            <img src={content.s1_img_4} alt="California Ragdoll breeders" />
            <img src={content.s1_img_5} alt="California Ragdoll breeders" />
          </div>
        </div>

        <div className="content">
          {content.s1_p_4}
        </div>

        <Heading3 text={content.s2_title} />
        <div className="content">
          {content.s2_p_1}
          <br />
          <br />
          {content.s2_p_2}
          <br />
          <br />
          {content.s2_p_3}
          <div>
            <iframe
              src={content.s2_video}
              style={{ width: "90%", height: "500px" }}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
            />
          </div>

          <br />
          {content.s2_p_4}
          <br />
          <div className='image-containers'>
            <img src={content.s2_img_1} alt="Ragdoll Breeder California" />
            <img src={content.s2_img_2} alt="Ragdoll Breeder California" />
          </div>
          {content.s2_p_5}
          <div className='image-containers'>
            <img src={content.s2_img_3} alt="Ragdoll kitten Breeder" />
            <img src={content.s2_img_4} alt="Ragdoll kitten Breeder" />
          </div>
        </div>

        <Heading3 text={content.s3_title} />

        <div className="content">
        {content.s3_p_1}
        <br></br><br></br>
        {content.s3_p_2}
        <img src={content.s3_img_1} alt="Los Angeles Ragdoll Kitten" />
        <Button style={{ marginRight: 'auto', marginLeft: 'auto', display: 'block' }}><Link to='/gallery/show-photos'>{intl({ id: 'header.showPhotos' })}</Link></Button>
          
        </div>

        <Heading3 text={content.s4_title} />
        <div className="content">
          {content.s4_p_1}
          <br />
          <br />
          {content.s4_p_2}
        </div>
      </div>
    </div> : <Header title={"Loading"}/>
  );
};
