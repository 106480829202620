import React from 'react';

import './Typography.scss';

const Heading4 = (props) => {
	const { text, color } = props;

	return (
		<h4 className='typography-heading4' style={{ color }}>
			{text}
		</h4>
	);
};

export default Heading4;
