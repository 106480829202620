import React, { useState, useEffect } from 'react';
import { useFormatMessage } from 'react-intl-hooks';

import Header from '../../components/Typography/Header';
import FujipawsContent from '../../components/FujipawsContent/FujipawsContent';
import BringKittenHome from 'components/BringKittenHome/BringKittenHome';

import BackgroundImgLeft from '../../assets/background-img-left.svg';
import BackgroundImgRight from '../../assets/background-img-right.svg';
import BackgroundImgBottom from '../../assets/background-img-bottom.svg';

import './AboutUs.scss';

const AboutUs = () => {
	const intl = useFormatMessage();

	const [finishloading,setfinishloading] = useState(false);
	const [content,setContent] = useState([]);

	useEffect(()=>{
		fetch(process.env.REACT_APP_API_END_POINT+'content/aboutZh.php')
			.then((response) => response.json())
			.then((data) => {
				setContent(data);
				setfinishloading(true);
			});
	}, [])

	return (finishloading ?
		<div className='about-us-page'>
			<Header title={intl({ id: 'about.title' })} />
			{content.map((v, i) => (
				<FujipawsContent
					key={i}
					title={v.title}
					titleColor={v.titleColor}
					subtitle={v.subtitle}
					subtitleColor={v.subtitleColor}
					detail={v.detail}
					img={v.img}
					sticker={v.sticker}
					button={v.button}
					i={i}
				/>
			))}
			<BringKittenHome />
			<img
				src={BackgroundImgLeft}
				alt='background left'
				className='background-image background-image-left'
			/>
			<img
				src={BackgroundImgRight}
				alt='background right'
				className='background-image background-image-right'
			/>
			<img
				src={BackgroundImgBottom}
				alt='background bottom'
				className='background-image background-image-bottom'
			/>
		</div> : <Header title={"Loading"}/>
	);
};

export default AboutUs;
