import React, { useEffect } from 'react';
import FaqItem from 'components/FaqItem/FaqItem';
import Heading3 from 'components/Typography/Heading3';

import './FaqList.scss';

const FaqList = ({ FaqData, searchField, searchResults }) => {
	useEffect(() => {
		if (searchResults.length) {
			for (let i = 0; i < searchResults.length; i++) {
				if (searchResults[i].index.length) {
					document.getElementById(searchResults[i].section).scrollIntoView();
					break;
				}
			}
		}
	}, [searchResults]);

	return (
		<div className='faq-list'>
			{FaqData &&
				FaqData()?.map((item, idx) => (
					<div className='list' key={idx} id={idx}>
						<Heading3 text={item.title} />
						<FaqItem
							searchResults={searchResults && searchResults[idx]}
							item={item.questions}
							searchField={searchField}
						/>
					</div>
				))}
		</div>
	);
};

export default FaqList;
