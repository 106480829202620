import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import DesktopNav from './DesktopNav/DesktopNav';
import MobileNav from './MobileNav/MobileNav';
import useWindowSize from '../../hooks/useWindowSize';

const NavBar = ({ history }) => {
	const size = useWindowSize();
	const isOnHomePage = history.location.pathname === '/' ? true : false;
	const [isWhite, setIsWhite] = useState(isOnHomePage ? false : true);

	useEffect(() => {
		const navbarToggle = () => {
			if (window.scrollY > 0) {
				setIsWhite(true);
			} else if (window.scrollY === 0 && isOnHomePage) {
				setIsWhite(false);
			}
		};
		window.addEventListener('scroll', () => navbarToggle());

		return window.removeEventListener('scroll', navbarToggle);
	}, [isOnHomePage]);

	const isMobileSize = () => {
		if (size.width >= 600) {
			return '58px';
		} else {
			return '76px';
		}
	};

	return (
		<div style={{ paddingBottom: isOnHomePage ? '0' : isMobileSize() }}>
			{size.width >= 600 ? (
				<DesktopNav isOnHomePage={isOnHomePage} isWhite={isWhite} />
			) : (
				<MobileNav isOnHomePage={isOnHomePage} isWhite={isWhite} />
			)}
		</div>
	);
};

export default withRouter(NavBar);
