import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import { useFormatMessage } from 'react-intl-hooks';
import PhotosGrid from '../../../components/PhotosGrid/PhotosGrid';

const ShowPhotos = () => {
	const intl = useFormatMessage();
	const [showPhotos, setShowPhotos] = useState([]);

	useEffect(() => {
		fetch(process.env.REACT_APP_API_END_POINT+'content/show-photo.php')
			.then((response) => response.json())
			.then((data) => {
				setShowPhotos(data);
			});
	}, []);

	return (
		<div>
			<Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/gallery/show-photos"} />
			</Helmet>
			<PhotosGrid content={showPhotos} title='SHOW PHOTOS' isFromShowContent />
		</div>
	);
};

export default ShowPhotos;
