import React from 'react';
import Paragraph from '../../Typography/Paragraph';
import './KittenCard.scss';

const KittenCard = ({ img, rate, comment, from }) => {
	return (
		<div className='card-container'>
			<div
				className='card-img'
				style={{
					backgroundImage: `url(${img})`,
				}}
			></div>
			<div className='card-words'>
				<Paragraph text={comment} />
			</div>
		</div>
	);
};

export default KittenCard;
