import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Paragraph from 'components/Typography/Paragraph';
import Placeholder from 'components/Typography/Placeholder';

import './FaqItem.scss';

const FaqItem = ({ item, searchResults }) => {
	const [showAnswer, setAnswer] = useState({});

	useEffect(() => {
		let newShowAnswer = {};
		searchResults &&
			searchResults.index.map((v) => {
				newShowAnswer[v] = true;
				return null;
			});
		setAnswer(newShowAnswer);
	}, [searchResults]);

	const handleShowAnswer = (i) => {
		const newShowAnswer = {};
		showAnswer[i]
			? (newShowAnswer[i] = !showAnswer[i])
			: (newShowAnswer[i] = true);
		setAnswer(newShowAnswer);
	};

	return (
		<div className='faq-item-container'>
			{item.map((question, i) => (
				<div className='faq-item' key={i}>
					<div className='faq-question' onClick={() => handleShowAnswer(i)}>
						<Paragraph text={question.q} />
						<button>
							{showAnswer[i] ? (
								<MinusOutlined style={{ fontSize: '22px' }} />
							) : (
								<PlusOutlined style={{ fontSize: '22px' }} />
							)}
						</button>
					</div>
					<div
						className={classnames('faq-answer', { showMore: showAnswer[i] })}
					>
						<Placeholder text={question.a} />
					</div>
				</div>
			))}
		</div>
	);
};

export default FaqItem;
