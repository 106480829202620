import React from 'react';

const iconData = {
	male: (
		<svg
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			// width='20.25'
			// height='31.5'
			viewBox='0 0 1000 1000'
			enableBackground='new 0 0 1000 1000'
		>
			<metadata>
				{' '}
				Svg Vector Icons : http://www.onlinewebfonts.com/icon{' '}
			</metadata>
			<g>
				<path d='M168.5,901.9c164.3,137.4,410,111.4,544.4-58.3c123.6-156,106.9-375.7-29.4-509.6l1.4-2l163.3-184.9l52,219.2c0,0,7.9,29.4,30.4,24.5c28-5.9,36.8-24.5,36.8-24.5S904.1,31.8,885.9,18.1c-18.1-13.7-394.3-9.3-409,7.8c-14.2,17.7-11.3,43.2,6.4,56.9l312.8,3L619.1,280l-1,2c-159.4-99.6-372.8-66.7-496.4,82.4C-12.5,526.2,6.6,767,168.5,901.9z M218,841C88,733.7,71.8,540.9,179.2,410.9C285.7,282.4,475,261.4,604.9,364.4c131.5,104,153.5,296.7,47.1,430.1C544.6,929.3,348.9,950.4,218,841z' />
			</g>
		</svg>
	),
	female: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			// width='20.25'
			// height='31.5'
			viewBox='0 0 20.25 31.5'
		>
			<path
				id='Icon_ionic-md-female'
				data-name='Icon ionic-md-female'
				d='M28.125,12.375a10.125,10.125,0,1,0-11.812,9.984v3.516H11.25V29.25h5.063v4.5h3.375v-4.5H24.75V25.875H19.688V22.359A10.123,10.123,0,0,0,28.125,12.375Zm-16.875,0A6.75,6.75,0,1,1,18,19.125,6.761,6.761,0,0,1,11.25,12.375Z'
				transform='translate(-7.875 -2.25)'
			/>
		</svg>
	),
	cat: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			// width='36'
			// height='33.75'
			viewBox='0 0 36 33.75'
		>
			<path
				id='Icon_awesome-cat'
				data-name='Icon awesome-cat'
				d='M20.432,13.5A13.506,13.506,0,0,0,9,19.543V13.5A6.757,6.757,0,0,0,2.25,6.75a2.25,2.25,0,0,0,0,4.5A2.253,2.253,0,0,1,4.5,13.5v18A4.5,4.5,0,0,0,9,36H21.375A1.125,1.125,0,0,0,22.5,34.875V33.75a2.25,2.25,0,0,0-2.25-2.25H18l9-6.75V34.875A1.125,1.125,0,0,0,28.125,36h2.25A1.125,1.125,0,0,0,31.5,34.875V20.381a8.91,8.91,0,0,1-2.25.319,9.015,9.015,0,0,1-8.818-7.2ZM31.5,6.75H27l-4.5-4.5V11.7a6.75,6.75,0,1,0,13.5,0V2.25Zm-5.062,5.625a1.125,1.125,0,1,1,1.125-1.125A1.125,1.125,0,0,1,26.438,12.375Zm5.625,0a1.125,1.125,0,1,1,1.125-1.125A1.125,1.125,0,0,1,32.063,12.375Z'
				transform='translate(0 -2.25)'
			/>
		</svg>
	),
	birthday: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			// width='27'
			// height='33'
			viewBox='0 0 27 33'
		>
			<path
				id='Icon_material-cake'
				data-name='Icon material-cake'
				d='M18,9a3,3,0,0,0,3-3,2.855,2.855,0,0,0-.435-1.545L18,0,15.435,4.455A2.855,2.855,0,0,0,15,6,3.009,3.009,0,0,0,18,9Zm6.9,14.985-1.6-1.6-1.62,1.6a5.315,5.315,0,0,1-7.335,0l-1.605-1.6-1.635,1.6A5.152,5.152,0,0,1,7.44,25.5a5.212,5.212,0,0,1-2.94-.915V31.5A1.5,1.5,0,0,0,6,33H30a1.5,1.5,0,0,0,1.5-1.5V24.585a5.212,5.212,0,0,1-2.94.915A5.152,5.152,0,0,1,24.9,23.985ZM27,13.5H19.5v-3h-3v3H9A4.494,4.494,0,0,0,4.5,18v2.31a2.945,2.945,0,0,0,2.94,2.94A2.879,2.879,0,0,0,9.51,22.4L12.72,19.2,15.915,22.4a3.008,3.008,0,0,0,4.155,0L23.28,19.2,26.475,22.4a2.9,2.9,0,0,0,2.07.855,2.945,2.945,0,0,0,2.94-2.94V18A4.472,4.472,0,0,0,27,13.5Z'
				transform='translate(-4.5)'
			/>
		</svg>
	),
	character: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40.5'
			height='22.5'
			viewBox='0 0 40.5 22.5'
		>
			<path
				id='Icon_awesome-fish'
				data-name='Icon awesome-fish'
				d='M23,6.75c-6.326,0-11.85,3.851-14.925,7.146L1.934,9.252A1.169,1.169,0,0,0,.025,10.282L1.725,18l-1.7,7.718a1.17,1.17,0,0,0,1.908,1.031L8.073,22.1C11.148,25.4,16.673,29.25,23,29.25c9.665,0,17.5-9,17.5-11.25S32.664,6.75,23,6.75Zm6.147,12.938A1.688,1.688,0,1,1,30.834,18,1.688,1.688,0,0,1,29.147,19.688Z'
				transform='translate(0 -6.75)'
			/>
		</svg>
	),
};

export default iconData;
