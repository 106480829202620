import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import {Helmet} from "react-helmet";

import Header from 'components/Typography/Header';
import Heading2 from '../../components/Typography/Heading2';
import Heading4 from '../../components/Typography/Heading4';
import Paragraph from '../../components/Typography/Paragraph';

import CatAnimation from '../../components/CatAnimation/CatAnimation';

import Button from '../../components/Button/Button';
import Popup from '../../components/Popup/Popup';

import './Deposit.scss';

const Deposit = () => {
	const [showPopup, setShowPopup] = useState({});

	const intl = useFormatMessage();

	const paymentMethods = [
		{
			name: intl({id: 'deposit.pop.PaypalTitel'}),
			key: 'paypal',
			barcode: intl({id: 'deposit.pop.PaypalImage'}),
			msg: intl({id: 'deposit.pop.PaypalText'}),
		},
		{
			name: intl({id: 'deposit.pop.VenmoTitel'}),
			key: 'venmo',
			barcode: intl({id: 'deposit.pop.VenmoImage'}),
			msg: intl({id: 'deposit.pop.VenmoText'}),
		},
	];

	return (
		<div className='deposit-page'>
			<Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/deposit"} />
			</Helmet>
			<div className='deposit-heading'>
				<Header title={intl({ id: 'deposit.title' })} color='#CEA697' />
				<Paragraph text={intl({ id: 'deposit.intro' })} textAlign='left' />
				<br />
				<Paragraph text={intl({ id: 'deposit.intro2' })} textAlign='left' />
				<CatAnimation />
			</div>
			<div className='deposit-questions'>
				<Heading4 text={intl({ id: 'deposit.price.title'})} />
				<Paragraph text={intl({ id: 'deposit.price.detail' })} textAlign='left' />
			</div>

			<div className='deposit-questions'>
				<Heading4 text={intl({ id: 'deposit.birthRight.title'})} />
				<Paragraph text={intl({ id: 'deposit.birthRight.detail' })} textAlign='left' />
			</div>

			<div className='deposit-questions'>
				<Heading4 text={intl({ id: 'deposit.priceCover.title'})} />
				<Paragraph text={intl({ id: 'deposit.priceCover.detail' })} textAlign='left' />
			</div>

			<div className='deposit-questions'>
				<Paragraph text={intl({ id: 'deposit.acceptMethods' })} textAlign='left' />
			</div>

			<div className='deposit-options-container'>
				<Heading4 text='Make Payment Now' />
				<div className='payment-options'>
					<div className='payments'>
						{paymentMethods.map((v) => (
							<div className='venmo-alipay'>
								<Button
									onClick={() =>
										setShowPopup((preState) => ({
											...preState,
											[v.name]: true,
										}))
									}
									alt={v.key}
								>
									{v.name}
								</Button>
								{showPopup[v.name] && (
									<Popup data={v} setShowPopup={setShowPopup} />
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Deposit;
