import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import { useFormatMessage } from 'react-intl-hooks';

import Header from '../../components/Typography/Header';
import Heading3 from "components/Typography/Heading3";
import Heading4 from "components/Typography/Heading4";

import FujipawsContent from "components/FujipawsContent/FujipawsContent";
import BringKittenHome from "components/BringKittenHome/BringKittenHome";

import BackgroundImgLeft from "../../assets/background-img-left.svg";
import BackgroundImgRight from "../../assets/background-img-right.svg";

import "./CatDetail.scss";

const CatDetail = ({ history }) => {
    const intl = useFormatMessage();
    const [currentPath, setCurrentPath] = useState("");
    const [catInfo, setCatInfo] = useState({"loading": true});
    const [path, setPath] = useState("");

    const currentPathName = history.location.pathname.split("/")[1];
    const currentCat = history.location.pathname.split("/")[2];

    useEffect(()=>{
        setCurrentPath(currentPathName);
        if (currentPath === "kings") {
            fetch(process.env.REACT_APP_API_END_POINT+'content/kings.php')
                .then((response) => response.json())
                .then((data) => {
                    let result = data.find((e) => e.pathname === currentCat);
                    console.log(result)
                    if(result){
                        setPath("/kings/"+result.pathname);
                        setCatInfo(result);
                    }else{
                        setCatInfo({"redirect": true});
                    }
                });
        } else if (currentPath === "queens") {
            fetch(process.env.REACT_APP_API_END_POINT+'content/queens.php')
                .then((response) => response.json())
                .then((data) => {
                    let result = data.find((e) => e.pathname === currentCat);
                    if(result){
                        setPath("/queens/"+result.pathname);
                        setCatInfo(result);
                    }else{
                        setCatInfo({"redirect": true});
                    }
                });
        }
	}, [currentCat, currentPath, currentPathName])

    return (
        <div className="cat-detail-wrapper">
            { path!=="" && <Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+path} />
			</Helmet>}
            { !catInfo.loading && catInfo.redirect && <Redirect to='/404' />}
            { !catInfo.loading && <div className="cat-detail-banner">
                <img
                    className="cat-main-photo"
                    src={catInfo.imageUrl}
                    alt="cat"
                />
                <div className="cat-name">
                    <Header color="#BC8A77" title={catInfo.name} />
                    {catInfo.fullName && (
                        <Heading4 color="#BC8A77" text={catInfo.fullName} />
                    )}
                    {catInfo.awards &&
                        catInfo.awards.map((v, i) => (
                            <Heading4 color="#BFA48F" text={v} />
                        ))}
                    {catInfo.detail &&
                        Object.entries(catInfo.detail).map((detail) => (
                            <div style={{ color: "#5A5A5A" }}>
                                <span>{detail[0].toUpperCase()}</span>:{" "}
                                {detail[1]}
                            </div>
                        ))}
                    {catInfo.thanks &&
                        <Heading4 color="#BFA48F" text={catInfo.thanks} />
                    }
                </div>
                <div>
                    {catInfo.content &&
                        catInfo.content.map((v, i) => (
                            <FujipawsContent
                                title={v.title}
                                detail={v.description}
                                img={{ imgSrc: v.img, isHorizontal: true }}
                                i={i}
                                key={v.title}
                            />
                        ))}
                </div>
                <div className="cat-images">
                    {catInfo.images &&
                        catInfo.images.map((img, index) => (
                            <img src={img} id={index} alt="cats" />
                        ))}
                </div>
            </div>}
            <img
                src={BackgroundImgLeft}
                alt="background left"
                className="background-image background-image-left"
            />
            <img
                src={BackgroundImgRight}
                alt="background right"
                className="background-image background-image-right"
            />
            <BringKittenHome />
        </div>
    );
};

export default withRouter(CatDetail);
