import React from 'react';

import './Button.scss';

const Button = (props) => {
	const { children, onClick, type, style } = props;

	return (
		<button className={`fujipaw-btn btn-${type}`} onClick={onClick} style={style}>
			<span>{children}</span>
		</button>
	);
};

export default Button;
