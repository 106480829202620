import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';

import KittensCarousel from 'components/KittensCarousel/KittensCarousel';
import HomeBanner from 'components/HomeBanner/HomeBanner';
import BringKittenHome from 'components/BringKittenHome/BringKittenHome';
import FujipawsContent from 'components/FujipawsContent/FujipawsContent';
import PhotosGrid from 'components/PhotosGrid/PhotosGrid';
import Button from 'components/Button/Button';
import Heading3 from 'components/Typography/Heading3';
import FadeInSection from 'components/FadeInContainer/FadeInContainer';

import './Home.scss';

const Home = () => {
  const intl = useFormatMessage();

  const [content,setContent] = useState([]);
  const [caralPhotosLink,setCaralPhotosLink] = useState([]);

  useEffect(()=>{
    fetch(process.env.REACT_APP_API_END_POINT+'content/homeKitten.php')
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
      });
    fetch(process.env.REACT_APP_API_END_POINT+'content/homeCarol.php')
      .then((response) => response.json())
      .then((data) => {
        setCaralPhotosLink(data);
      });
  }, [])

  const aboutUsVersion =
    localStorage.getItem("lang") === "en" || !localStorage.getItem("lang")
    ? "/about-en"
    : "/about-zh";

  const HomeContent = [
    {
      title: intl({
        id: 'aboutus.Fujipaws.title',
        defaultMessage: 'About Fujipaws',
      }),
      titleColor: '#D6A89A',
      detail: intl({
        id: 'aboutus.Fujipaws.desc',
      }),
      img: {
        imgSrc: intl({id: 'aboutus.Fujipaws.img'}),
        isHorizontal: false,
        backgroundColor: '#EFD2C7',
        rotate: -8,
      },
      button: [intl({
        id: 'global.readmore',
      })],
      link: [aboutUsVersion],
    },
    {
      title: intl({
        id: 'aboutus.ourRagdolls.title',
        defaultMessage: 'Our Ragdolls',
      }),
      titleColor: '#D6A89A',
      subtitle: '',
      subtitleColor: '#BFA48F',
      detail: intl({
        id: 'aboutus.ourRagdolls.desc',
        defaultMessage:
          'Far curiosity incommode now led smallness allowance. Favour bed assure son things yet. She consisted consulted elsewhere happiness disposing household any old the. Widow downs you new shade drift hopes small.',
      }),
      img: {
        imgSrc: intl({id: 'aboutus.ourRagdolls.img'}),
        isHorizontal: false,
        backgroundColor: '#EFD2C7',
        rotate: -8,
      },
      button: [
        intl({id: 'showPhoto.title'}),
        intl({id: 'kings.title'}),
        intl({id: 'queens.title'})],
      link: ['/gallery/show-photos', '/kings', '/queens'],
    },
  ];

  return (
    <div>
      <Helmet>
          <link rel="canonical" href={intl({id: 'global.siteAddress'})} />
      </Helmet>
      <HomeBanner />
      <div className='home-content'>
        {HomeContent.map((v, i) => (
          <FujipawsContent
            key={i}
            title={v.title}
            titleColor={v.titleColor}
            subtitle={v.subtitle}
            subtitleColor={v.subtitleColor}
            detail={v.detail}
            img={v.img}
            button={v.button}
            i={i}
            link={v.link}
          />
        ))}
      </div>
      <FadeInSection>
        <div className='photos-grid-container'>
          <Heading3 text={intl({id: 'home.previousKittens.title'})} />
          {content.length > 0 && <PhotosGrid content={content} />}
          <Link to='/previous-kittens'>
          <Button>{
              intl({
                id: 'home.previousKittensButton',
              })} 
          </Button>
          </Link>
        </div>
      </FadeInSection>

      <div className='photos-grid-container'>
        <Heading3 text={
          intl({
            id: 'homeContent.carol',
          })} 
        />
        <div className='carols-container'>
          {caralPhotosLink.length>0 && caralPhotosLink.map(link => (
            <img src={link.src} alt='with carol' />
          ))}
        </div>
      </div>
      
      <KittensCarousel />
      <BringKittenHome />
    </div>
  );
};

export default Home;
