import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useFormatMessage } from 'react-intl-hooks';

import NavBar from './components/Navbar/Navbar.js';
import Footer from './components/Footer/Footer.js';

import { Routes } from './constants/routes.js';

import 'antd/dist/antd.css';
import './App.css';

function App() {
  const intl = useFormatMessage();
  return (
    <Router>
      <Helmet>
        <title>{intl({id: 'global.seo.title'})}</title>
        <meta name="description" content={intl({id: 'global.seo.description'})}></meta>
      </Helmet>
      <NavBar />
        <Switch>
          {Routes.map((v) => (
            <Route
              key={v.path}
              exact={v.isExact}
              path={v.path}
              render={() => {
                window.scrollTo(0, 0);
                return v.component;
              }}
            />
          ))}
          <Redirect path='*' to='/404' />
        </Switch>
      <Footer />
    </Router>
  );
}

export default App;
