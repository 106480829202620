import emailjs from 'emailjs-com';

export const sendMessage = (emailData) => {

    const templateParams = {
        firstName: emailData.firstName,
        lastName: emailData.lastName,
        email: emailData.email,
        phone: emailData.phone,
        residential: emailData.residential,
        employment: emailData.employment,
        currentPetsNum: emailData.currentPetsNum,
        message: emailData.message,
        hearAboutUs: emailData.hearAboutUs,
        time_sent: new Date().toISOString().split('T')[0],
    };
    return emailjs.send (
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_EMAILJS_USER_ID
        )
};
