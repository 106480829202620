import React, { useState, useEffect } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import {Helmet} from "react-helmet";

import Header from '../../components/Typography/Header';

import CatPreview from '../../components/CatPreview/CatPreview';

import './kings.scss';

const KingsPage = () => {
	const intl = useFormatMessage();

	const [kingsData,setkingsData] = useState([]);

	useEffect(()=>{
		fetch(process.env.REACT_APP_API_END_POINT+'content/kings.php')
			.then((response) => response.json())
			.then((data) => {
				setkingsData(data);
			});
	}, [])

	return (
		<div className='kings-page'>
			<Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/kings"} />
			</Helmet>
			<Header
				title={intl({id: 'kings.title'})}
			/>
			<div
				className={`kings-content ${
					kingsData.length <= 2 ? 'full-width' : null
				}`}
			>
				{kingsData.map((king) => (
					<CatPreview key={king.id} data={king} id={king.id} />
				))}
			</div>
		</div>
	);
};

export default KingsPage;
