import React from 'react';
import HomePage from '../pages/Home/Home';
import KingsPage from '../pages/Kings/kings';
import QueensPage from '../pages/Queens/queens';
import CatDetailPage from '../pages/CatDetail/CatDetail';
import AvailableKittens from '../pages/AvailableKittens/AvailableKittens';
import PreviousKittens from '../pages/PreviousKittens/PreviousKittens';
import ShowResultPage from '../pages/ShowResults/showResults';
import FaqPage from '../pages/Faq/faq';
import ContactUsPage from '../pages/ContactUs/ContactUs';
import Deposit from '../pages/Deposit/Deposit';
import AfterPayment from '../pages/AfterPayment';
import AboutUs from '../pages/AboutUs/AboutUs';
import { AboutUsEng } from 'pages/AboutUs/AboutUsEng';
import ShowPhotos from '../pages/Gallery/ShowPhotos/ShowPhotos';
import FunPhotos from '../pages/Gallery/FunPhotos/FunPhotos';
import KittyDetail from '../pages/KittyDetail/KittyDetail';
import NotFound from 'pages/NotFound';

export const Routes = [
	{
		isExact: true,
		path: '/',
		component: <HomePage />,
	},
	{
		isExact: true,
		path: '/about-zh',
		component: <AboutUs />,
	},
	{
		isExact: true,
		path: '/about-en',
		component: <AboutUsEng />,
	},
	{
		isExact: true,
		path: '/kings',
		component: <KingsPage />,
	},
	{
		isExact: true,
		path: '/kings/:name',
		component: <CatDetailPage />,
	},
	{
		isExact: true,
		path: '/queens',
		component: <QueensPage />,
	},
	{
		isExact: true,
		path: '/queens/:name',
		component: <CatDetailPage />,
	},
	{
		isExact: true,
		path: '/available-kittens',
		component: <AvailableKittens />,
	},
	{
		isExact: true,
		path: '/available-kittens/:name',
		component: <KittyDetail />,
	},
	{
		isExact: true,
		path: '/previous-kittens',
		component: <PreviousKittens />,
	},
	{
		isExact: true,
		path: '/previous-kittens/:name',
		component: <KittyDetail />,
	},
	{
		isExact: true,
		path: '/gallery/show-results',
		component: <ShowResultPage />,
	},
	{
		path: '/faq',
		component: <FaqPage />,
	},
	{
		path: '/contactus',
		component: <ContactUsPage />,
	},
	{
		isExact: true,
		path: '/gallery/show-photos',
		component: <ShowPhotos />,
	},
	{
		isExact: true,
		path: '/gallery/fun-photos',
		component: <FunPhotos />,
	},
	{
		path: '/deposit',
		component: <Deposit />,
	},
	{
		path: '/thankyou',
		component: <AfterPayment />,
	},
	{
		path: '/404',
		component: <NotFound />,
	}
];
