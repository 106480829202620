import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import {Helmet} from "react-helmet";

import Header from 'components/Typography/Header';

const AfterPayment = () => {
	const intl = useFormatMessage();
	return (
		<div>
			<Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/thankyou"} />
			</Helmet>
			<Header title={intl({
					id: 'afterPayment.title',
					defaultMessage: 'Thank you for your payment. Your transaction has been completed, and a receipt for your purchase has been emailed to you. Log into your PayPal account to view transaction details. ',
				})}>
			</Header>
		</div>
	);
};

export default AfterPayment;
