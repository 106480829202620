import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ItemsCarousel from 'react-items-carousel';
import { useFormatMessage } from 'react-intl-hooks';

import arrowLeft from '../../assets/icon-arrow-forward.svg';
import arrowRight from '../../assets/icon-arrow-backward.svg';

import Header from 'components/Typography/Header';
import Heading4 from '../Typography/Heading4';
import Placeholder from '../Typography/Placeholder';

import Button from 'components/Button/Button';
import useWindowSize from '../../hooks/useWindowSize';

import './HomeBanner.scss';

const HomeBanner = () => {
	const [activeItemIndex, setActiveItemIndex] = useState(0);
	const [bannerArr,setbannerArr] = useState([]);

	useEffect(()=>{
		fetch(process.env.REACT_APP_API_END_POINT+'content/homeBanner.php')
			.then((response) => response.json())
			.then((data) => {
				setbannerArr(data);
			});
	}, [])
	
	const size = useWindowSize();
	const intl = useFormatMessage();

	return (
		<div className='home-banner-container'>
			<ItemsCarousel
				requestToChangeActive={setActiveItemIndex}
				activeItemIndex={activeItemIndex}
				numberOfCards={1}
				leftChevron={
					<img
						style={{ width: size.width < 690 ? '40%' : '20px' }}
						src={arrowLeft}
						alt='arrow-icon'
					/>
				}
				rightChevron={
					<img
						style={{ width: size.width < 690 ? '40%' : '20px' }}
						src={arrowRight}
						alt='arrow-icon'
					/>
				}
				chevronWidth={size.width < 690 ? 30 : 60}
			>
				{bannerArr.length>0 && bannerArr.map((v, i) => (
					<div key={i} className='home-banner-block'>
						<img className='banner-img' src={v.src} alt='banner' />
						{i === 0 && (
							<div className='banner-words-block'>
								<Heading4 text={intl({ id: 'homeBanner.lineOne' })} color='#6d6d6d' />
								<Header title={intl({ id: 'homeBanner.lineTwo' })} />
								<Placeholder text={intl({ id: 'homeBanner.lineThree' })} />
								<Placeholder text={intl({ id: 'homeBanner.lineFour' })} />

								<Link to='/available-kittens'>
									<Button children={intl({ id: 'homeBanner.actionButton' })} />
								</Link>
							</div>
						)}
					</div>
				))}
			</ItemsCarousel>
		</div>
	);
};

export default HomeBanner;
