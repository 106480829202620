import React, { useState, useRef, useEffect } from 'react';

import './FadeInContent.scss';

const FadeInSection = ({children}) => {
    const domRef = useRef();
    const [isVisible, setVisible] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(entries => { 
        if (entries[0].isIntersecting) {
          setVisible(true);
          observer.unobserve(domRef.current);
        }
      });
      
      observer.observe(domRef.current);
      
      return () => observer.unobserve(domRef.current); // eslint-disable-line react-hooks/exhaustive-deps
    }, []);
  
    return (
        <section ref={domRef} className={`fade-in-section ${isVisible ? ' is-visible' : ''}`}>
            { children }
        </section>);
  };

export default FadeInSection;
