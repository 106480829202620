import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import './LangSwitch.scss';

const LangSwitch = () => {

	const location = useLocation();
	const history = useHistory();

	const handleChangeLang = () => {
		if (localStorage.getItem('lang') === 'en' || !localStorage.getItem('lang')) {
			if (location.pathname === '/about-en') {
				history.push('/about-zh')
			}
			localStorage.setItem('lang', 'zh');
		} else {
			if (location.pathname === '/about-zh') {
				history.push('/about-en')
			}
			localStorage.setItem('lang', 'en');
		}
		window.location.reload();
	};

	return (
		<div className='lang-switch'>
			<span onClick={handleChangeLang}>
				{!localStorage.getItem('lang') ? '中文' : localStorage.getItem('lang') === 'en' ? '中文' : 'En'}
			</span>
			
		</div>
	);
};

export default LangSwitch;
