import React, { useState, useCallback } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';

import Heading2 from 'components/Typography/Heading2';
import CartoonStar from 'assets/icon-patrick-star.svg';
import CartoonThunder from 'assets/icon-cartoon-thunder.svg';
import CartoonDot from 'assets/icon-cartoon-dot.svg';
import CatAnimation from '../CatAnimation/CatAnimation';
import { useFormatMessage } from 'react-intl-hooks';

import './Banner.scss';

const Banner = ({ searchField, setSearchField }) => {
	const intl = useFormatMessage();
	const [value, setValue] = useState('');

	const delayedQuery = useCallback(
		debounce((q) => setSearchField(q), 1000),
		[],
	);

	return (
		<div className='banner'>
			<img
				src={CartoonThunder}
				alt='cartoon thunder'
				className='icon thunder'
			/>
			<img src={CartoonDot} alt='cartoon dot' className='icon dot' />
			<img src={CartoonStar} alt='cartoon star' className='icon star' />

			<div className='banner-content'>
				<Heading2 color='#cea697' text={intl({id: 'faq.help'})} />
				<div className='search-bar'>
					<Input
						size='large'
						placeholder={intl({id: 'global.search'})}
						value={value}
						prefix={<SearchOutlined />}
						onChange={(e) => {
							delayedQuery(e.target.value);
							setValue(e.target.value);
						}}
					/>
				</div>
				<CatAnimation />
			</div>
		</div>
	);
};

export default Banner;
