import React from 'react';
import { Link } from 'react-router-dom';

import Heading2 from 'components/Typography/Heading2';
import Heading4 from 'components/Typography/Heading4';
import Paragraph from 'components/Typography/Paragraph';
import Button from 'components/Button/Button';
import FadeInSection from 'components/FadeInContainer/FadeInContainer';

import './FujipawsContent.scss';

const FujipawsContent = ({
	title,
	titleColor,
	subtitle,
	subtitleColor,
	detail,
	img,
	sticker,
	button,
	i,
	link,
}) => {
	const { imgSrc, backgroundColor, rotate, isHorizontal } = img;

	const reverse = i % 2 === 0 ? true : false;

	return (
		<FadeInSection>
			<div
				className='content-wrapper'
				style={{
					flexDirection: reverse ? 'row' : 'row-reverse',
				}}
			>
				<div className='content-words'>
					{title && <Heading2 text={title} style={{ color: titleColor ? titleColor : '#4d4d4d' }} />}
					{subtitle && <Heading4 text={subtitle} color={subtitleColor} />}
					<Paragraph text={detail} />
					{button && (
						<div
							className='content-button'
						>
							{button[0] && (
								<Link to={link[0]}>
									<Button children={button[0]} />
								</Link>
							)}
							{button[1] && (
								<Link to={link[1]}>
									<Button children={button[1]} />
								</Link>
							)}
							{button[2] && (
								<Link to={link[2]}>
									<Button children={button[2]} />
								</Link>
							)}
							
						</div>
					)}
				</div>
				<div className='content-pics'>
					<div
						className='img-container'
						style={{
							backgroundColor: backgroundColor ? backgroundColor : '#EFD2C7',
							transform: `rotate(${rotate ? rotate : 8}deg)`,
						}}
					>
						<img
							src={imgSrc}
							alt='img'
							style={{
								transform: `rotate(${rotate ? -rotate : -8}deg)`,
								width: isHorizontal ? '300px' : 'auto',
								height: isHorizontal ? 'auto' : '300px',
								maxWidth: '300px',
								objectFit: 'cover',
							}}
						/>
					</div>
				</div>
				{sticker && (
					<img
						className='sticker'
						src={sticker && sticker.stickerSrc}
						alt='img'
						style={sticker && sticker.position}
					/>
				)}
			</div>
		</FadeInSection>
	);
};

export default FujipawsContent;
