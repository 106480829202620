import React from 'react';

const PhotosGridRow = ({ pics, isOddLine, lessThanTwo  }) => {
	const gridTemplate = isOddLine ? '1fr 2fr 1fr' : '2fr 1fr 1fr';
	const newGridTemplate = lessThanTwo ? 'repeat(1 auto)' : '1fr 2fr 2fr';

	return (
		<div
			className='grid-container'
			style={{
				display: 'grid',
				gridTemplateColumns: lessThanTwo ? newGridTemplate : gridTemplate,
			}}
		>
			{pics &&
				pics.map(
					(v) =>
						v.src && (
							<img
								src={v.src}
								alt='img'
								style={{
									height: '21.7vw',
									width: '100%',
									objectFit: 'cover'
								}}
								key={v.key}
							/>
						),
				)}
		</div>
	);
};

export default PhotosGridRow;
