import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import {Helmet} from "react-helmet";

import Banner from '../../components/Banner/Banner';
import FaqList from '../../components/FaqList/FaqList';
import BringKittenHome from '../../components/BringKittenHome/BringKittenHome';
import Paragraph from '../../components/Typography/Paragraph';
import Button from '../../components/Button/Button';

import logo from '../../assets/navbar-logo.svg';

import FaqData from './FaqData';

import './FaqPage.scss';

const FaqPage = () => {
	const intl = useFormatMessage();

	const [searchField, setSearchField] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	useEffect(() => {
		let newResults = [];
		const handleSearch = (keyWord) => {
			let reg = new RegExp(keyWord, 'i');
			FaqData().map((v, j) => {
				newResults.push({ section: j, index: [] });
				for (let i = 0; i < v.questions.length; i++) {
					if (reg.test(v.questions[i].a)) {
						newResults[j].index.push(i);
					}
				}
				return null;
			});
			setSearchResults(newResults);
		};

		searchField === '' ? setSearchResults([]) : handleSearch(searchField);
	}, [searchField]);

	return (
		<div className='faq-page'>
			<Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/faq"} />
			</Helmet>
			<Banner searchField={searchField} setSearchField={setSearchField} />
			<FaqList FaqData={FaqData} searchResults={searchResults} />
			<div className='faq-more-question'>
				<img alt='logo' className='logo' src={logo} />
				<Paragraph
					text={intl({
						id: 'faq.paragraph',
						defaultMessage: 'Didn’t find what you looking for? Ask us now!',
					})}
				/>
				<Link to='./contactus'>
					<Button>
						{intl({
							id: 'faq.button.contactUs',
							defaultMessage: 'CONTACT US!',
						})}
					</Button>
				</Link>
			</div>
			<BringKittenHome />
		</div>
	);
};

export default FaqPage;
