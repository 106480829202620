import React, { useState, useEffect } from "react";
import Button from "../Button/Button";
import PhotosGridRow from "./PhotosGridRow/PhotosGridRow";
import classnames from "classnames";
import Header from "../Typography/Header";
import FadeInSection from "components/FadeInContainer/FadeInContainer";
import Heading2 from "components/Typography/Heading2";
import { useFormatMessage } from 'react-intl-hooks';

import "./PhotosGrid.scss";

const PhotosGrid = ({
    content,
    buttonName,
    title,
    isFromShowContent = false,
}) => {

    const intl = useFormatMessage();
    const [frontImgArr, setFrontImgArr] = useState([]);
    const [backImgArr, setBackImgArr] = useState([]);
    const [loadMore, setLoadMore] = useState(false);

    useEffect(() => {
        const frontImg = content.slice(0, 9);
        setFrontImgArr(splitContent(frontImg, frontImg.length));
        if (content.length > 9) {
            const backImg = content.slice(9);
            setBackImgArr(splitContent(backImg, backImg.length));
        }
    }, [content]);

    const splitContent = (data, length) => {
        let result = [];
        let i = 0;
        while (i < length) {
            if ((i + 1) % 3 === 0) {
                result.push([data[i - 2], data[i - 1], data[i]]);
                i++;
            }
            if (data.length - i < 3 && data.length % 3 !== 0) {
                result.push([data[i], data.length - i === 2 && data[i + 1]]);
                break;
            }
            i++;
        }
        return result;
    };

    if (isFromShowContent) {
        return (
			<div className="gallery-images-container">
				<Header title={intl({id: 'showPhoto.title'})} style={{ textAlign: 'center', color: '#cea697', marginBottom: '2rem' }} />
				<div className="gallery-images">
					{/* link = google drive link */}
					{content?.map(link => (
						<img src={link} alt="kitty" />
					))}
				</div>
			</div>
        );
    }

    return (
        <div className="photos-grid-container">
            {title && <Header title={title} />}
            <div className="photos-grid-front">
                {frontImgArr &&
                    frontImgArr.map((v, i) => (
                        <FadeInSection key={i}>
                            <PhotosGridRow
                                pics={v}
                                isOddLine={(i + 1) % 2 === 0 ? true : false}
                                lessThanTwo={v.length < 3 && v[1] === false}
                            />
                        </FadeInSection>
                    ))}
            </div>
            <div
                className={classnames("photos-grid-block", {
                    active: loadMore,
                })}
            >
                <div
                    className={classnames("photos-grid-back", {
                        active: loadMore,
                    })}
                >
                    {backImgArr &&
                        backImgArr.map((v, i) => (
                            <FadeInSection key={i}>
                                <PhotosGridRow
                                    pics={v}
                                    isOddLine={(i + 1) % 2 === 0 ? true : false}
                                    lessThanTwo={v.length < 3 && v[1] === false}
                                />
                            </FadeInSection>
                        ))}
                </div>
                {content.length > 9 && (
                    <div
                        className={classnames("photos-grid-button", {
                            active: loadMore,
                        })}
                    >
                        <Button
                            type="white"
                            children={loadMore ? intl({id: 'global.loadLess'}) : intl({id: 'global.loadMore'})}
                            onClick={() => setLoadMore(!loadMore)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PhotosGrid;
