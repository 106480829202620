import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import {Helmet} from "react-helmet";

import Header from '../../components/Typography/Header';
import ContactForm from '../../components/ContactForm/ContactForm';
import BackgroundImgLeft from '../../assets/background-img-left.svg';
import BackgroundImgRight from '../../assets/background-img-right.svg';
import CatAnimation from '../../components/CatAnimation/CatAnimation';

import './ContactUs.scss';

const ContactUS = () => {
	const intl = useFormatMessage();

	return (
		<div className='contact-us-page'>
			<Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/contactus"} />
			</Helmet>
			<Header title={intl({
					id: 'contactUs.title',
				})} />
			<CatAnimation />
			<p>
				{intl({
					id: 'contactUs.content',
				})}
			</p>
			<ContactForm />
			<img
				src={BackgroundImgLeft}
				alt='background left'
				className='background-image background-image-left'
			/>
			<img
				src={BackgroundImgRight}
				alt='background right'
				className='background-image background-image-right'
			/>
		</div>
	);
};

export default ContactUS;
