import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';

import FooterMenu from 'components/FooterMenu/FooterMenu';
import ContactForm from 'components/ContactForm/ContactForm';
import Paragraph from 'components/Typography/Paragraph';
import Heading2 from 'components/Typography/Heading2';
import Popup from 'components/Popup/Popup';

import EmailIcon from 'assets/icon-email.png';
import PhoneIcon from 'assets/icon-phone.png';
import WechatIcon from 'assets/icon-wechat.png';
import RedIcon from 'assets/icon-red.png';
import InstagramIcon from 'assets/icon-instagram.png';
import NavbarLogo from 'assets/navbar-logo.svg';

import './Footer.scss';

const Footer = ({ history }) => {
	const intl = useFormatMessage();
	const [atContactPage, setAtContactPage] = useState(true);
	const [showPopup, setShowPopup] = useState({});
	const currentPathName = history.location.pathname;

	const dropElement = useRef(null);

	useEffect(() => {
		if (currentPathName === '/contactus') {
			setAtContactPage(true);
		} else {
			setAtContactPage(false);
		}
	}, [currentPathName]);

	const handleCloseDropdown = (e) => {
		if (dropElement.current && !dropElement.current.contains(e.target)) {
			setShowPopup({});
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleCloseDropdown, true);
		return () => {
			document.removeEventListener('click', handleCloseDropdown, true);
		};
	});

	const socialMedia = [
		{
			name: intl({id: 'footer.pop.emailTitle'}),
			key: 'email',
			icon: EmailIcon,
			msg: intl({id: 'footer.pop.email'}),
			text: 'mailto:'+intl({id: 'global.email'}),
		},
		{
			name: intl({id: 'footer.pop.phoneTitle'}),
			key: 'phone',
			icon: PhoneIcon,
			msg: intl({id: 'footer.pop.phone'}),
		},
		{
			name: intl({id: 'footer.pop.WeChatTitel'}),
			key: 'wechat',
			icon: WechatIcon,
			barcode: intl({id: 'footer.pop.WeChatImage'}),
			msg: intl({id: 'footer.pop.WeChatText'}),
		},
		{
			name: intl({id: 'footer.pop.RedTitel'}),
			key: 'red',
			icon: RedIcon,
			barcode: intl({id: 'footer.pop.RedImage'}),
			msg: intl({id: 'footer.pop.RedText'}),
		},
		{
			name: intl({id: 'footer.pop.InsTitel'}),
			key: 'instagram',
			icon: InstagramIcon,
			barcode: intl({id: 'footer.pop.InsImage'}),
			msg: intl({id: 'footer.pop.InsText'}),
		},
	];

	return (
		<div className='footer'>
			{!atContactPage && (
				<div className='footer-content-container'>
					<div className='footer-header'>
						<img alt='logo' className='logo' src={NavbarLogo} />
						<Heading2 text={intl({id: 'contactUs.title'})} />
					</div>
					<div className='footer-content'>
						<ContactForm />
						<FooterMenu />
					</div>
				</div>
			)}

			<div className='footer-base'>
				<div>
					<Paragraph text={intl({id: 'footer.copyright1'})} />
					<Paragraph text={intl({id: 'footer.copyright2'})} />
				</div>
				<div className='social-media-container'>
					{socialMedia.map((v, i) => (
						<div key={i} className='social-media' ref={dropElement}>
							<img
								onClick={() =>
									setShowPopup((prevState) => ({
										...prevState,
										[v.name]: true,
									}))
								}
								alt={v.key}
								src={v.icon}
							/>
							{showPopup[v.name] && (
								<Popup data={v} setShowPopup={setShowPopup} />
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default withRouter(Footer);
