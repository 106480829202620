import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";

import Header from 'components/Typography/Header';
import CatPreview from 'components/CatPreview/CatPreview';

import './queens.scss';

const QueensPage = () => {
	const intl = useFormatMessage();

	const [queensData,setqueensData] = useState([]);

	useEffect(()=>{
		fetch(process.env.REACT_APP_API_END_POINT+'content/queens.php')
			.then((response) => response.json())
			.then((data) => {
				setqueensData(data);
			});
	}, [])

	return (
		<div className='queens-page'>
			<Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/queens"} />
			</Helmet>
			<Header
				title={intl({
					id: 'queens.title',
					defaultMessage: 'Queens',
				})}
			/>
			<div className={`queens-content ${
					queensData.length <= 2 ? 'full-width' : null
				}`}>
				{queensData.map((queen) => (
					<CatPreview data={queen} key={queen.id} />
				))}
			</div>
		</div>
	);
};

export default QueensPage;
