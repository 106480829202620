import { useFormatMessage } from 'react-intl-hooks';


const FaqData = () => {
const intl = useFormatMessage();

return (
[
    {
        title: intl({id:'faq.aboutRagdoll'}) ,
        questions: [
            {
                q: intl({id: 'faq.question.whatIsRagdoll'}),
                a: intl({id: 'faq.answer.whatIsRagdoll'}),
            },
            {
                q: intl({id: 'faq.question.ragdollColor', }),
                a: intl({id:'faq.answer.ragdollColor'})
            },
            {
                q: intl({id: 'faq.question.ragdollCharacter', }),
                a: intl({id:'faq.answer.ragdollCharacter'})
            },
        ],
    },
    {
        title: intl({id:'faq.waitList' }),
        questions: [
            {
                q: intl({id:'faq.question.waitList' }),
                a: intl({id:'faq.answer.waitList' })
            },
        ],
    },
    {
        title: intl({id:'faq.welcomeKittenHome'}) ,
        questions: [
            {
                q:intl({id:'faq.question.welcomeKittenHome' }),
                a:intl({id:'faq.answer.welcomeKittenHome' })
            },
            {
                q:intl({id:'faq.question.welcomeKittenTips' }),
                a:intl({id:'faq.answer.welcomeKittenTips' })
            },

        ],
    },
    {
        title: intl({id:'faq.price'}) ,
        questions: [
            {
                q:intl({id:'faq.question.price' }),
                a:intl({id:'faq.answer.price' }),
            },
            {
                q:intl({id:'faq.question.priceDetail' }),
                a:intl({id:'faq.answer.priceDetail' }),
            },
            {
                q:intl({id:'faq.question.priceDiscount' }),
                a:intl({id:'faq.answer.priceDiscount' }),
            },
            {
                q:intl({id:'faq.question.purchaseInstruction' }),
                a:intl({id:'faq.answer.purchaseInstruction' }),
            },
            {
                q:intl({id:'faq.question.visitKitten' }),
                a:intl({id:'faq.answer.visitKitten' }),
            },
        ],
    },
    {
        title: intl({id:'faq.contactUs'}) ,
        questions: [
            {
                q:intl({id:'faq.question.contactUs' }),
                a:intl({id:'faq.answer.contactUs' }),
            },
        ],
    },
]
)
} 
export default FaqData;

