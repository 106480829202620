import React from 'react';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';


import Heading3 from 'components/Typography/Heading3';
import Paragraph from 'components/Typography/Paragraph';
import Button from 'components/Button/Button';
import FadeInSection from 'components/FadeInContainer/FadeInContainer';

import kitten from 'assets/bringKittenHome.jpg';
import dot from 'assets/dot.svg';

import './BringKittenHome.scss';

const BringKittenHome = () => {
	const intl = useFormatMessage();

	return (
		<FadeInSection>
			<div className='bring-kitten-home-container'>
				<div className='kitten-content'>
					<Heading3 text={intl({ id: 'faq.bringHome.title'})} textAlign='left' />
					<Paragraph
						textAlign='left'
						text={intl({ id: 'faq.bringHome.detail'})}
					/>
					<Link to='/deposit'>
						<Button>{intl({ id: 'kittyDetail.button.reserveNow'})}</Button>
					</Link>
				</div>
				<div className='kitten-image'>
					<img className='kitten' src={kitten} alt='kitten' />
					<img className='dot' src={dot} alt='dot' />
				</div>
			</div>
		</FadeInSection>
	);
};

export default BringKittenHome;
