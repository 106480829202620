import React, { useState, useEffect } from "react";
import { useFormatMessage } from "react-intl-hooks";
import {Helmet} from "react-helmet";

import Header from "components/Typography/Header";
import Heading3 from "components/Typography/Heading3";
import Heading4 from '../../components/Typography/Heading4';

import { withRouter } from "react-router-dom";

import "./PreviousKittens.scss";
import Paragraph from "components/Typography/Paragraph";

const PreviousKittens = ({ history }) => {
  const intl = useFormatMessage();

  const currentPathName = history.location.pathname;

  const [previousKittens,setPreviousKittens] = useState([]);

	useEffect(()=>{
		fetch(process.env.REACT_APP_API_END_POINT+'content/previous-kittens.php')
			.then((response) => response.json())
			.then((data) => {
				setPreviousKittens(data);
			});
	}, [])

  return (
    <div className="previous-kittens-page">
      <Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/previous-kittens"} />
			</Helmet>
      <Header
        title={intl({
          id: "previousKittens.title",
          defaultMessage: "Previous Kittens",
        })}
      />
      <div style={{ margin: 'auto 15%' }}>
        <Paragraph text={intl({ id: 'kittens.intro' })} />
      </div>
      <div className="kittens-list">
        {previousKittens.length>0 && previousKittens.map((kitten) => (
          <div className="kitten">
            <div
              className="kitten-img"
              onClick={() => history.push(`${currentPathName}/${kitten.path}`)}
            >
              <img
                src={kitten.image[0]}
                alt="cat"
                width="280px"
                height="280px"
                style={{ borderRadius: "10px", objectFit: 'cover' }}
              />
            </div>
            <Heading3 text={kitten[`name${localStorage.lang}`].toUpperCase()} />
            <Heading4 text={kitten[`location${localStorage.lang}`].toUpperCase()} color="#BFA48F" />
            <div
              className="preview-link"
              onClick={() => history.push(`${currentPathName}/${kitten.path}`)}
            >
              More photos
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(PreviousKittens);
