import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Heading3 from "components/Typography/Heading3";
import Heading4 from "components/Typography/Heading4";
import Placeholder from "components/Typography/Placeholder";
import Button from "components/Button/Button";

import "./CatPreview.scss";

const CatPreview = ({ data, history, match }) => {
  const { fullName, name, imageUrl, pathname, detail } = data;
  const [currentPath, setCurrentPath] = useState("");

  const currentPathName = history.location.pathname;

  useEffect(() => {
    setCurrentPath(currentPathName);
  }, [currentPath, currentPathName]);

  return (
    <div className="cat-preview-wrapper">
      <div onClick={() => history.push(`${currentPath}/${pathname}`)}>
        <img
          className="cat-img"
          src={imageUrl}
          alt="cat"
          width="500px"
          height="500px"
          style={{ objectFit: "cover" }}
        />
        <Heading3 color="#BC8A77" text={name} />
        <Heading4 color="#BC8A77" text={fullName} />
        <Placeholder color="grey" text={detail.color} />
      </div>
      <Button onClick={() => history.push(`${currentPath}/${pathname}`)} style={{ marginTop: '1rem' }}>
        More Details
      </Button>
    </div>
  );
};

export default withRouter(CatPreview);
