import React, { useState, useEffect } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import {Helmet} from "react-helmet";

import Header from '../../components/Typography/Header';

import ShowResultItem from '../../components/ShowResultItem/ShowResultItem';

import './showResults.scss';

const ShowResultPage = () => {
	const intl = useFormatMessage();

	const [showResults,setshowResults] = useState([]);

	useEffect(()=>{
		fetch(process.env.REACT_APP_API_END_POINT+'content/show-result.php')
			.then((response) => response.json())
			.then((data) => {
				setshowResults(data);
			});
	}, [])

	return (
		<div className='show-results-page'>
			<Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+"/gallery/show-results"} />
			</Helmet>
			<Header
				title={intl({
					id: 'showResults.title',
				})}
			/>
			<div
				className={`show-results-content`}
			>
				{showResults.map((result) => (
					<ShowResultItem data={result} id={result.id} />
				))}
			</div>
		</div>
	);
};

export default ShowResultPage;
