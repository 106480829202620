import React, { useState, useEffect } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { useFormatMessage } from "react-intl-hooks";
import {Helmet} from "react-helmet";

import ItemsCarousel from "react-items-carousel";
import Header from "components/Typography/Header";
import Heading2 from "components/Typography/Heading2";
import Paragraph from "components/Typography/Paragraph";
import useWindowSize from "hooks/useWindowSize";
import iconData from "data/kittyDetail";
import Button from "components/Button/Button";
import arrowLeft from "assets/icon-arrow-forward.svg";
import arrowRight from "assets/icon-arrow-backward.svg";

import "./KittyDetail.scss";

const KittyDetail = ({ history }) => {
    const intl = useFormatMessage();

    const [availableKitten,setavailableKitten] = useState([]);
    useEffect(()=>{
		fetch(process.env.REACT_APP_API_END_POINT+'content/available-kittens.php')
			.then((response) => response.json())
			.then((data) => {
				setavailableKitten(data);
			});
	}, [])

    const [catInfo, setCatInfo] = useState({"loading": true});
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [path, setPath] = useState('');

    const size = useWindowSize();
    const currentCat = history.location.pathname.split("/")[2];
    const currentPathName = history.location.pathname.split("/")[1];

    const handleObjFind = (data) => {
        data.find(function (item) {
            item.id === currentCat && setCatInfo(item);
            return null;
        });
    };

    useEffect(() => {
        if (availableKitten.length > 0) {
            if (currentPathName === "available-kittens") {
                handleObjFind(availableKitten);
                fetch(process.env.REACT_APP_API_END_POINT+'content/available-kittens.php')
                    .then((response) => response.json())
                    .then((data) => {
                        let result = data.find((e) => currentCat === e.path);
                        if(result){
                            setPath("/available-kittens/"+result.path);
                            setImages(result.image);
                            setCatInfo(result);
                        }else{
                            setCatInfo({"redirect": true});
                        }
                    });
            } else {
                fetch(process.env.REACT_APP_API_END_POINT+'content/previous-kittens.php')
                    .then((response) => response.json())
                    .then((data) => {
                        let result = data.find((e) => currentCat === e.path);
                        if(result){
                            setPath("/previous-kittens/"+result.path);
                            setImages(result.image);
                            setCatInfo(result);
                        }else{
                            setCatInfo({"redirect": true});
                        }
                    });
            }
        }
    }, [availableKitten]);

    const moment = (birthday) => {
        if(birthday){
            const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
            const bDay = birthday.split('.');
			const month = +bDay[0]-1;
			const day = +bDay[1];
            const rVal = `${monthName[month]} ${day}`;
            return rVal;
        }
        return "Invalid date";
    }

    return (
        <div className="kitty-detail-container">
            { path!=="" && <Helmet>
				<link rel="canonical" href={intl({id: 'global.siteAddress'})+path} />
			</Helmet>}
            <Heading2 className="title"
                text={intl({
                    id: "kittyDetail.title",
                    defaultMessage: "KITTY DETAIL",
                })}
            />
            { !catInfo.loading && catInfo.redirect && <Redirect to='/404' />}
            { !catInfo.loading && <div>
                <div className="img-carousel-block">
                    <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={1}
                        leftChevron={
                            <img
                                style={{ width: size.width > 768 ? "50%" : "30%" }}
                                src={arrowLeft}
                                alt="arrow-icon"
                            />
                        }
                        rightChevron={
                            <img
                                style={{ width: size.width > 768 ? "50%" : "30%" }}
                                src={arrowRight}
                                alt="arrow-icon"
                            />
                        }
                        gutter={30}
                        chevronWidth={40}
                    >
                        {images && images.map((v) => (
                                <>
                                    { currentPathName === "available-kittens"? (
                                        <img
                                            className="cat-img"
                                            src={v}
                                            alt="catImg"
                                        />
                                    ) : (
                                        <img
                                            className="cat-img"
                                            src={v}
                                            alt="catImg"
                                        />
                                    )}
                                </>
                            ))}
                    </ItemsCarousel>
                </div>
                {currentPathName === "available-kittens" ? (
                    <>
                        <Header
                            title={`${catInfo[`name${localStorage.lang}`]} ${catInfo.isAdopted ? '(Adopted)' : ''}`}
                            color="#BC8A77"
                        />
                        <div className="cat-info-container">
                            <div className="cat-info-block">
                                <i>
                                    {catInfo.gender === "girl"
                                        ? iconData.female
                                        : iconData.male}
                                </i>
                                <Paragraph
                                    text={
                                        catInfo.gender &&
                                        catInfo.gender.charAt(0).toUpperCase() +
                                            catInfo.gender.slice(1)
                                    }
                                />
                            </div>
                            <div className="cat-info-block">
                                <i>{iconData.cat}</i>
                                <Paragraph text={catInfo.color} />
                            </div>
                            <div className="cat-info-block">
                                <i>{iconData.birthday}</i>
                                <Paragraph
                                    text={moment(catInfo.birthday)}
                                />
                            </div>

                            {catInfo.character && (
                                <div className="cat-info-block">
                                    <i>{iconData.character}</i>
                                    <Paragraph text={catInfo.character} />
                                </div>
                            )}
                        </div>
                        <Link to="/deposit">
                            <div className="kitty-detail-button">
                                {!catInfo.isAdopted && (
                                    <Button
                                        children={intl({
                                            id: "kittyDetail.button.reserveNow",
                                            defaultMessage: "RESERVE NOW",
                                        })}
                                    />
                                )}
                            </div>
                        </Link>
                        <div className="kitty-detail-parents-container">
                            {catInfo.parents && catInfo.parents.fatherImg && (
                                <div className="kitty-detail-parents-block">
                                    <Heading2
                                        text={intl({
                                            id: "kittyDetail.heading.father",
                                            defaultMessage: "FATHER",
                                        })}
                                        color="#BC8A77"
                                    />
                                    <img
                                        src={catInfo.parents.fatherImg}
                                        alt="catImg"
                                    />
                                </div>
                            )}

                            {catInfo.parents && catInfo.parents.motherImg && (
                                <div className="kitty-detail-parents-block">
                                    <Heading2
                                        text={intl({
                                            id: "kittyDetail.heading.mother",
                                            defaultMessage: "MOTHER",
                                        })}
                                        color="#BC8A77"
                                    />
                                    <img
                                        src={catInfo.parents.motherImg}
                                        alt="catImg"
                                    />
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <Header
                            title={catInfo[`name${localStorage.lang}`]}
                            color="#BC8A77"
                        />
                        <Link to="/available-kittens">
                            <div className="kitty-detail-button">
                                <Button
                                    children={intl({
                                        id: "kittyDetail.button.checkAvailability",
                                        defaultMessage: "CHECK AVAILABLE KITTENS",
                                    })}
                                />
                            </div>
                        </Link>
                    </>
                        
                )}
            </div>}
        </div>
    );
};

export default withRouter(KittyDetail);
