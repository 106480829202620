import React from 'react';
import './Typography.scss';

export default function Paragraph(props) {
	const { text, color, textAlign } = props;

	return (
		<p className='typography-paragraph' style={{ color, textAlign }}>
			{text}
		</p>
	);
}
