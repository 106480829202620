import React, { useState, useEffect } from 'react';
import ItemsCarousel from 'react-items-carousel';
import KittenCard from '../../components/KittensCarousel/KittenCard/KittenCard';
import arrowLeft from '../../assets/icon-arrow-forward.svg';
import arrowRight from '../../assets/icon-arrow-backward.svg';
import backgroundImg from '../../assets/backgroundImg2.png';
import titleLeft from '../../assets/icon-kitten-title-left.svg';
import titleRight from '../../assets/icon-kitten-title-right.svg';
import Heading2 from '../Typography/Heading2';
import useWindowSize from '../../hooks/useWindowSize';

import './KittensCarousel.scss';
import { useFormatMessage } from 'react-intl-hooks';

const KittensCarousel = () => {
	const [activeItemIndex, setActiveItemIndex] = useState(0);
	const chevronWidth = 60;
	const size = useWindowSize();
	const intl = useFormatMessage();

	const [KittensCard,setKittensCard] = useState([]);

	useEffect(()=>{
		fetch(process.env.REACT_APP_API_END_POINT+'content/homeClub.php')
			.then((response) => response.json())
			.then((data) => {
				setKittensCard(data);
			});
	}, [])

	return (
		<div className='kittens-container'>
			<img
				className='kittens-background-img'
				src={backgroundImg}
				alt='backgroundImg'
			/>
			<div className='kittens-title'>
				<img src={titleLeft} alt='icon' />
				<Heading2 text={intl({ id: 'homeContent.members.title' })} style={{ color: '#4d4d4d' }} />
				<img src={titleRight} alt='icon' />
			</div>
			<div
				style={{
					padding: `0 ${chevronWidth}px`,
					maxWidth: '1280px',
					margin: 'auto',
				}}
			>
				<ItemsCarousel
					requestToChangeActive={setActiveItemIndex}
					activeItemIndex={activeItemIndex}
					numberOfCards={size.width > 690 ? 3 : 1}
					gutter={20}
					leftChevron={<img src={arrowLeft} alt='arrow-icon' />}
					rightChevron={<img src={arrowRight} alt='arrow-icon' />}
					outsideChevron
					chevronWidth={chevronWidth}
				>
					{KittensCard.map((v, i) => (
						<div
							key={i}
							style={{
								height: 'auto',
								background: '#fff',
								maxWidth: '400px',
								margin: 'auto',
							}}
						>
							<KittenCard
								img={v.imgSrc}
								rate={v.rate}
								comment={v.comment}
								from={v.from}
							/>
						</div>
					))}
				</ItemsCarousel>
			</div>
		</div>
	);
};

export default KittensCarousel;
