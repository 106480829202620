import React from 'react';

import './Typography.scss';

const Heading2 = (props) => {
	const { text, style } = props;

	return (
		<h2 className='typography-heading2' style={style}>
			{text}
		</h2>
	);
};

export default Heading2;
